import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'

const endpoint = 'warehouse'
const Actions = crudActions(endpoint)
const getByProducts = () => axios.get(`${endpoint}?sorting=list`)
const setGlobalVariable = ({ commit }, value) => {
  commit('SET_GLOBAL_VARIABLE', value)
}

const startFulfillment = (_, payload) => axios.post(`${payload.urlAction}/${payload.id}/check-fulfillment`)

export default {
  ...Actions,
  getByProducts,
  setGlobalVariable,
  startFulfillment,
}
